import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      light: '#d6eff1',
      main: '#010103',
      dark: '#010103',
      contrastText: '#fff',
      success: '#31c274',
      heading: '#96A3CC',
      pale: '#2B2B2B',
      medium: '#67739C',
      lightMain: '#f9f9f9',
      lightContrast: '#aaaaaa',
    },
    primaryButton: {
      main: 'rgba(105, 33, 196, .1)',
      contrastText: '#6921c4',
    },
    secondary: {
      main: '#1045ff',
    },
    secondaryLight: {
      main: 'rgba(16,69,255,0.04)',
    },
    blueLight: {
      light: '#fff',
      main: '#3682ff',
      contrastText: '#fff',
    },
    success: {
      main: '#31c274',
    },
    danger: {
      main: '#ec7376',
    },
    error: {
      main: '#ff3636',
    },
    disabled: {
      main: '#A4A8AC',
      light: '#6D7D8B',
      hot: '#545B7D',
      black: '#000',
    },
    mode: 'light',
  },
  typography: {
    fontFamily: "'Outfit', sans-serif !important",
    h1: {
      fontSize: '13vw',
      fontWeight: '400',
      lineHeight: '48px',
    },
    h2: {
      fontSize: '82px',
      fontWeight: '700',
      lineHeight: '66px',
    },
    h3: {
      fontSize: '64px',
      fontWeight: '700',
      lineHeight: '66px',
    },
    font44: {
      fontSize: '44px',
      fontWeight: '900',
      lineHeight: 'normal',
    },
    font24: {
      fontSize: '24px',
      fontWeight: '500',
      lineHeight: 'normal',
    },
    font20: {
      fontSize: '20px',
      fontWeight: '700',
      lineHeight: 'normal',
    },
    font18: {
      fontSize: '18px',
      fontWeight: '700',
      lineHeight: 'normal',
    },
    font14: {
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: 'normal',
    },
    font12: {
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: 'normal',
    },
    font10: {
      fontSize: '10px',
      fontWeight: '400',
      lineHeight: 'normal',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      1400: 1400,
      1380: 1380,
      1520: 1520,
      1920: 1920,
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          '&:-webkit-autofill': {
            '-webkit-box-shadow': '0 0 0 30px #fff inset',
            '-webkit-text-fill-color': 'var(--color-disabled-hot)',
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          '& .MuiPopover-paper.MuiMenu-paper': {
            boxShadow: '0px 2px 24px 0px rgba(0,0,0,0.15)',
            padding: '8px',
            '& .MuiList-padding': {
              padding: '0',
            },
            '& .MuiMenuItem-root': {
              borderRadis: '6px',
              height: '40px',
              '&:hover': {
                background: '#f7f7f7',
              },
            },
          },
        },
      },
    },
  },
});

export default theme;
