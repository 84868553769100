import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';
import Loader from '../common/Loader';

// import Layout from '../Layout';
// import Protected from './protected';
// import Loader from '../common/Loader';
// import UnProtected from './unProtected';

const Home = lazy(() => import('../pages/Home'));
const ContactUs = lazy(() => import('../pages/ContactUs'));

const MainRouts = () => (
  <BrowserRouter>
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/contact-us' element={<ContactUs />} />
      </Routes>
    </Suspense>
  </BrowserRouter>
);
export default MainRouts;
