import React from 'react';

import { ThemeProvider } from '@mui/material/styles';

import './assets/css/index.css';
import theme from './theme/theme';
import MainRoutes from './routes';

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <MainRoutes />
    </ThemeProvider>
  );
};

export default App;
