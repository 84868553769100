import React from 'react';
import { Stack } from '@mui/material';

import { Logo } from '../assets/constant';

export default function Loader() {
  return (
    <Stack
      sx={{
        position: 'absolute',
        left: '0',
        top: '0',
        height: '100vh',
        width: '100vw',
        background: '#000',
      }}
      alignItems='center'
      justifyContent='center'
    >
      <Stack
        p={3}
        sx={{
          borderRadius: '40px',
          border: '1px solid #000',
          background: '#000',
          boxShadow: 'none',
          // backdropFilter: 'blur(17px)',
        }}
      >
        <Logo />
      </Stack>
    </Stack>
  );
}
